<script setup lang="ts">

const props = defineProps<{
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    background?: string;
    placeholder?: string;
}>();

const emit = defineEmits(['error'])

const src = props.src.trim() == "" ? undefined : props.src;

const provider = computed(() => {
    if (src == undefined) {
        return undefined;
    }
    if (src.startsWith("/")) {
        return undefined;
    }
    return src.includes("http") ? undefined : "imagekit";
});


</script>

<template>
    <NuxtImg v-if="src" :provider="provider" :src="src" :alt="alt" :width="width" :height="height"
    :placeholder="placeholder"
    @error="emit('error', $event)"
    :background="background" />
</template>